/*************************************
Header
*************************************/
.header{ background:transparent; width:100%; padding:0 75px;  position: fixed; width: 100%; top: 0; left: 0; z-index: 10; }
.header.darkHeader{ background: #000; }

.top-header .notification-drop-down ul li a {
    cursor: text;
}

.top-header{ text-align: right; }
.top-header ul{ margin: 0; padding: 0; list-style: none; display: flex; justify-content: flex-end; align-items: center; padding:20px 0 0; }
.top-header ul li{ margin-right:25px; }
.top-header ul li:last-child{ margin-right: 0; }  
.top-header ul li a{ color: #fff; font-size: 14px;  }
.top-header ul li a:hover{ text-decoration: none; color:#F7941E; }


.logo{float:left;padding:25px 0}
.logo img {height: 35px;}
.navigation{float:right}
.navigation ul{ margin: 0; padding: 0; }
.navigation li a{ color:#fff; font-size:14px;text-transform:uppercase; display: block;}
/*.navigation li.hover a.parent, .navigation li a.active {color:#33aadb}*/
.navigation li a i {margin-left: 5px; display: none;}
.navigation li a:hover{color:#F7941E}
.navigation li.custom-btn{margin:27px 0px 27px 10px}
.navigation li.custom-btn a{ color:#F7941E;font-size:14px;text-transform:uppercase;border:1px solid #F7941E;border-radius:20px;padding:8px 20px}
.navigation li.custom-btn a:hover {color:#fff; background: #D77400;}
.navigation li.cta{margin:27px 10px}
.navigation li.cta a{color:#fff;font-size:14px;text-transform:uppercase;background:#F7941E;border-radius:20px;border:1px solid #F7941E;padding:8px 20px}
.navigation li.cta a:hover {color:#fff; background:#D77400; border-color:#D77400}

.dropdowns a,.dropdowns li,.dropdowns nav,.dropdowns ul{margin:0;padding:0}
.dropdowns a{text-decoration:none}
.toggleMenu{display:none}
.nav-site{list-style:none;}
.nav-site:after,.nav-site:before{content:" ";display:table}
.nav-site:after{clear:both}
.nav-site ul{list-style:none}
.nav-site a{padding:36px 10px; }
.nav-site li{position:relative}
.nav-site>li{float:left}
.nav-site>li>a{display:block}
.nav-site li .submenu {position:absolute;left:-9999px; border:1px solid #eee; border-top: none; transition: opacity 0.3s ease 0s; opacity: 0;}
.nav-site li:hover > .submenu {left: 0; width: 250px; background: #fff; z-index: 9; opacity: 1;}
.nav-site>li.hover>.submenu{left:0; width: 250px; background: #fff; z-index: 9; opacity: 1;}
.nav-site li li.hover .submenu{left:100%;top:0}
.nav-site li li a{display:block;position:relative;z-index:100; padding: 10px 15px; border-bottom:1px solid #eee; text-transform: none; color:#7f8c8d; transition:all 0.3s ease 0s;}
.nav-site li li:last-child a {border-bottom:none;}
.nav-site li li a:hover {background: #f7f7f7;}
.nav-site li li li a{z-index:200}
.nav-site>li.hover>.submenu.mega-menu {width: 450px; padding-bottom: 15px;}
.mega-menu ul {width: 50%; float: left;}
.mega-menu ul:last-child {border-left: 1px solid #33aadb;}
.submenu ul li span {display: block; margin-bottom: 10px; font-size: 14px; font-weight: 400; padding: 5px 15px;}
.submenu.product-drop ul li a span {display: block; font-size: 11px; font-weight: 400; padding: 0; color:#7f8c8d; margin: 0; line-height: normal;}
.submenu.product-drop ul li a {font-size: 14px; font-weight: 400; color:#1e2b38;line-height: normal;}
.submenu.product-drop ul li a:hover {color:#33aadb;}
.alignBadge .css-zza0ns-MuiBadge-badge{right: -10px!important; top:-5px;background-color: #f7941d;}


@media screen and (max-width:480px) {

    .top-header ul li{ margin-right: 15px; }
    .top-header ul li a{ font-size: 12px; }

}