#btn-download-exe {
  width: 90%;
  height: 48px;
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 5px 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  text-transform: none;
}

#btn-download-exe:disabled {
  width: 90%;
  height: 48px;
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  color: white;
  margin: 5px 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  text-transform: none;
}

.download-box{ background: url(../../../assets/download-bg.jpg) no-repeat left center; padding:80px 0; }
.download-icon{ margin-right: 50px; }
.download-title{ margin-bottom: 30px; }
.download-title h3{ font-size:34px; }
.download-title p{ color: #fff; margin: 0; }
.download-requirements p{ color:#f7941d; margin-bottom: 15px; }
.download-requirements ul{ margin: 0 0 30px; padding: 0 0 0 15px; }
.download-requirements ul li{ color: #fff; font-size: 16px; margin-bottom:8px; }
.download-requirements ul li span{ color:#f7941d;  }



.download-info {
  width: 410px;
  margin: 10px auto 20px;
  color: #fff;
  text-align: left;
  position: relative;
  padding-left: 150px;
}

.download-info img {
  position: absolute;
  left: 0;
  top: 0;
  width: 130px;
  opacity: 1;
}

.download-info-left {
  font-weight:300;
  font-size:36px;
  color: #f7941d;
}

.download-info-left span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
}

.download-info-right {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}

.download-box .btn {
  margin-bottom: 15px;
}


.run-info {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
  line-height: 20px;
  font-weight: normal;
}

.install-info-box { padding: 50px 0;
  text-align: center;
  margin: 0 auto 30px;
}

.install-info-box h4 {
  line-height: 30px;
  margin-bottom: 5px;
  color: #fff;
}

.install-info-box h4 strong {
  color: #f7941d;
}

.install-info-box p {
  margin-bottom:30px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
}

.install-info-box a {
  display: inline-block;
  padding: 10px 25px;
}

.download-main-box{padding:60px 0; background: rgba(255, 255, 255, 0.07);}
.download-tutorial-box {
  
  text-align: center;
}

/* .download-tutorial-box .tutorial-col,
.download-tutorial-box .tutorial-col:nth-child(2n) {
  width: 32%;
  margin-right: 2%; border-radius: 10px; overflow: hidden;
} */

/* .download-tutorial-box .tutorial-col:last-child {
  margin-right: 0;
} */

.tutorial-box-download {
  display: flex; flex-wrap: wrap;
  margin-bottom: 20px;
}

/* .download-license-text {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  color: white;
  border: none;
}

.download-license-text:disabled {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  color: white;
  border: none;
  opacity: 0.5;
} */

.download-license-margin {
  margin-top: 15px;
}

.license-btn-row .btn{ margin-right: 20px;}
.license-btn-row .btn:last-child{ margin-right: 0;}

.download-btn-row { margin-bottom: 0;   }
.inner-content-box h2.accordion-header {
  margin: 0;
}

.install-video-call{ margin: 0 auto; max-width:600px; width: 100%; }

.breadcrumb-box ul li.breadcrumb-item:first-letter{ text-transform: uppercase; }

.modal-open .App{ filter: blur(5px); -webkit-filter: blur(5px); }

.guidelineModal{ background: rgba(0,0,0,0.8); z-index: 2200000000; display: none !important; align-items: center; justify-content: center; }
.guidelineModal.show{ display: flex !important; }
.modal.guidelineModal .modal-dialog{ position:static; width:800px; max-height: calc(100% - 100px); overflow: auto; max-width: 100%; transform: none; margin: 0; }
.modal.guidelineModal .modal-dialog .modal-content{ height: 100%; border-radius:20px; }
.guidelineModal .modal-body{ padding:50px; text-align: left; color: #000; }
.guidelineModal .modal-body h2{ color: #f7941d; text-align: left;  }
.guidelineModal .modal-body ul{ margin: 0 0 30px; padding: 0 0; list-style: none; }
.guidelineModal .modal-body ul li{ margin-bottom:30px; line-height: 28px; font-size: 16px; position: relative; }

.guidelineModal .modal-body ul li strong{ font-weight: 500; display: block; margin: 0 0 5px; font-size: 18px }
.guidelineModal .primary-btn{ margin: 0 auto; display: block; }


@media only screen and (max-width:1500px){

  .download-box{ padding: 50px 0; }
  .download-title h3 { font-size: 28px; margin: 0 0 3px; }
  .download-title { margin-bottom:15px; }
  .download-requirements p{ margin-bottom: 10px; }
  .download-requirements ul li{ font-size: 14px; margin-bottom:5px; }
  .download-requirements ul{ margin: 0 0 20px; }
  .download-description p{ font-size: 14px; }

}

@media screen and (max-width: 768px) {
  
  .download-box{ padding:40px 0; }
  .download-icon{ margin: 0 0 20px; }
  .download-icon img{width: 130px;}
  .download-title h3{ font-size: 30px; }
  .tutorial-box-download{ display: block; }
  /* .download-tutorial-box .tutorial-col, .download-tutorial-box .tutorial-col:nth-child(2n){ width: 100%; margin: 0 0 20px; } */
  .install-info-box{ word-break: break-all; }
  .download-info{ width: 100%; text-align: center; padding: 0; }
  .download-info img{ position: static; margin: 0 auto 10px;  }
  .download-progress-bar.exe-progress-bar{ width: 240px; }
  .journey-title h3{ font-size: 28px; }
  .install-video-call{ max-width: 100%; }

  .top-header ul li a{ text-decoration: underline; color:#f7941d; }
  .download-box{ position: relative; }
  .download-box::after { content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.6); }
  .download-box .container{ position: relative; z-index: 2; }


}

@media screen and (max-width: 480px) {

  .license-btn-row .btn{ margin: 0 0 20px; }

}