.tut-video {
  border-radius: 5px;
}
.tut-video-container {
  position: relative;
}
.tut-video-title {
  width: 100%;
  font-size: 10px;
  color: #ffffff;
  position: absolute;
  top: 80%;
  float: right;
  text-align: center;
}

.how-to-begin-rigth-panel {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  background-color: #f2f7f8;
}
.started-box {
  padding: 70px 0;
}

.started-left,
.started-right {
  width: 46%;
}

.started-intro {
  margin-bottom: 60px;
  font-size: 16px;
  color: #fff;
}

.started-paragraph {
  margin-bottom: 32px;
}

.timeline-col {
  margin-bottom: 10px;
  padding-bottom: 50px;
  padding-left: 90px;
  position: relative;
}
.timeline-col:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.timeline-col:before {
  content: "";
  background: url(../../assets/timeline-icon.png) no-repeat;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 0;
  background-size: 100%;
}
.timeline-col:after {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 15px;
  top: auto;
  bottom: 0;
  width: 1px;
  height: calc(100% - 40px);
}
.timeline-col:last-child:after {
  display: none;
}
.timeline-col h4 {
  margin-bottom: 10px;
}
.timeline-col p {
  margin: 0;
}

.app-tutorial-col.tutorial-col {
  width: 100%;
  margin: 0;
  max-width: 350px;
}

.tutorial-box {
  display: flex;
  flex-wrap: wrap;
}

.tutorial-col {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.tutorial-col > img {
  vertical-align: top;
  width: 100%;
}
.play-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0;
  border: none;
  font-size: 40px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play-btn img {
  width: 25px;
}

.tutorial-col:nth-child(3n) {
  margin-right: 0;
}

.tutorial-col video {
  width: 100%;
  vertical-align: top;
  height: 233px;
}

.tutorial-title {
  margin-bottom: 20px;
}

.tutorial-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  width: 40px;
  height: 40px;
}

.tutorial-btn svg {
  opacity: 0.5;
}

@media screen and (max-width: 1400px) {
  .tutorial-col video {
    height: 200px;
  }
}

@media screen and (max-width: 1200px) {
  .tutorial-col video {
    height: 168px;
  }
}

@media screen and (max-width: 991px) {
  .tutorial-col {
    width: 49%;
  }
  .tutorial-col:nth-child(2n) {
    margin-right: 0;
  }
  .tutorial-col:nth-child(2n + 1) {
    margin-right: 2%;
  }
  .tutorial-col video {
    height: 191px;
  }
}

@media screen and (max-width: 768px) {
  .tutorial-col {
    width: 100%;
  }
  .tutorial-col:nth-child(2n) {
    margin-right: 0;
  }
  .tutorial-col:nth-child(2n + 1) {
    margin-right: 0;
  }
  .tutorial-col video {
    height: auto;
  }
  .tutorial-box-download {
    display: block;
  }
  .download-tutorial-box .tutorial-col,
  .download-tutorial-box .tutorial-col:nth-child(2n) {
    width: 100%;
    margin: 0 0 20px;
  }
  .install-info-box {
    word-break: break-all;
  }
  .journey-title h3 {
    font-size: 28px;
  }

  .started-left,
  .started-right {
    width: 100%;
  }
  .started-left,
  .started-intro {
    margin-bottom: 30px;
  }
  .timeline-col {
    padding-bottom: 30px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 480px) {
  .license-btn-row .btn {
    margin: 0 0 20px;
  }
}
