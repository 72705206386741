.letter-left, .letter-right {
  width: 48%;
}

.letter-left h2, .started-intro h2{  color: #f7941d;
font-size: 36px;
font-weight: 300;
margin: 0 0 30px;
padding: 0;
line-height: 114%;
text-align: left; }
.letter-left p {
  margin: 0 0 30px;
  font-size: 16px;
}

.letter-info {
  margin-bottom: 30px;
}

.letter-info > label {
  display: block;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
}

.letter-info ul {
  padding: 0 0 0 15px;
  margin: 0;
  list-style: disc;
}

.letter-info ul li {
  color: #fff;
  font-size: 16px;
  line-height: 27px;
}

.letter-right img {
  width: 100%;
  vertical-align: top;
}

@media screen and (max-width: 767px){
.letter-left h2, .started-intro h2 {
    font-size: 26px;
    margin: 0 0 20px;
  }
}