.notification-main-box {
  max-width: 652px;
  margin: 0 auto;
  max-height: 500px;
  overflow-y: auto;
}

.notification-title {
  margin-bottom: 28px;
}

.notification-title h2 {
  margin: 0;
  font-size: 32px;
}

.notification-inner-box {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 40px 30px;
  margin-bottom: 10px;
}

.notification-header {
  position: relative;
  padding-left: 60px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
  min-height: 58px;
}

.notification-header-profile {
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7941d;
  border-radius: 100%;
}

.notification-header h5 {
  color: #f7941d;
  font-size: 18px;
  margin: 0 0 5px;
  font-weight: 400;
}

.notification-header p {
  color: rgba(255, 255, 255, 0.3);
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.notification-description p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}

.header-drop-down {
  display: none;
  position: absolute;
  right: 100px;
  top: 48px;
  width: 300px;
  z-index: 10;
  background: #3d3d3d;
  border-radius: 10px;
  padding: 50px 20px 20px 20px;
}
.header-drop-down::after {
  content: "";
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 10px solid #3d3d3d;
  position: absolute;
  right: 145px;
  top: -10px;
}
.header-drop-down-inner {
  overflow-y: auto;
  max-height: 450px;
}
.header-drop-down.open {
  display: block;
}

.header-drop-down ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline !important;
  text-align: left;
}
.header-drop-down ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding: 15px 0;
  padding-left: 50px;
  font-weight: 300;
}
.header-drop-down ul > li:last-child {
  border: none;
}
.header-drop-down ul > li a {
  font-weight: 300;
}
.header-drop-down ul > li h6 {
  font-size: 16px;
  font-weight: 300;
  color: #f7941d;
}
.header-drop-down ul > li p {
  color: #fff;
  font-size: 12px;
}
.header-drop-down ul > li span {
  display: block;
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
}
.header-drop-down ul .notification-header-profile {
  top: 15px;
  color: #fff;
  left: 0;
}
.markallread {
  font-size: 12px;
  color: #f7941d;
  text-decoration: none;
}
.badge-icon-parent {
  position: relative;
}
.badge-icon {
  position: absolute;
  background-color: #f7941d;
  color: #ffffff;
  top: -12px;
  right: -17px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.alignBadge ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding: 15px 0;
  padding-left: 50px;
  font-weight: 300;
}
.alignBadge > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  text-align: left;
}
.alignBadge ul > li a {
  font-weight: 300;
}
.alignBadge li a {
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px;
  font-weight: 700;
}
.markallreadspan {
  position: absolute;
  top: 18px;
  right: 20px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
