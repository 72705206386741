/*************************************
Basic CSS
*************************************/

html {scroll-behavior: smooth; }

*{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  line-height: 1.3;
}
*:hover,
*:focus {
  outline: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  /* color: #fff; */
  background: #000000;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}
img,
svg {
  max-width: 100%;
}
.clear {
  clear: both;
}
a {
  text-decoration: none;
  color: #f7941e;
}
input[type="button"],
input[type="submit"],
button,
a {
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
}
input,
textarea,
button {
  font-family: "Roboto", sans-serif;
}
p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
h1 {
  color: #f7941e;
  font-weight: 300;
  font-size: 50px;
  margin: 0 0 30px;
}
h2 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 30px;
}
h3 {
  color: #f7941e;
  font-weight: 300;
  font-size: 27px;
  margin: 0 0 10px;
}
h4 {
  color: #f7941e;
  font-weight: 300;
  font-size: 22px;
  margin: 0 0 10px;
}
h6 {
  color: #f7941e;
  font-weight: normal;
  font-size: 18px;
  margin: 0 0 5px;
}
.btnlandingpage {
  border-radius: 40px;
  color: #fff;
  font-weight: 300;
  line-height: normal;
  vertical-align: top;
  border: none;
}
.btnlandingpage-signup {
  border-radius: 40px;
  color: #fff;
  font-weight: 300;
  line-height: normal;
  vertical-align: top;
  border: none;
}
.submit-btn-landing-page {
  background-color: #f7941e !important;
  font-size: 18px !important;
  padding: 0 40px !important;
  height: 46px;
}
.text-primary {
  color: #f7941e !important;
}

/*************************************
Top Section
*************************************/

.top-section {
  background: url(../../assets/top-bg.jpg) no-repeat top center;
}
.banner {
  min-height: 1350px;
  padding: 150px 0 0;
}
.carbon-logo {
  margin-bottom: 30px;
}
.carbon-logo img {
  width: 280px;
}
.carbon-logo sup {
  font-size: 18px;
  margin-top: -3px;
  margin-left: 5px;
}
.banner p {
  font-size: 26px;
  max-width: 750px;
  margin: 0 auto 30px;
  line-height: 34px;
}
.access-btn {
  background: #f7941e;
  padding: 15px 40px;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 22px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}
.access-btn-signup {
  background: #f7941e;
  padding: 20px 40px;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 22px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}
.access-btn span {
  display: block;
  text-transform: none;
  font-size: 12px;
  font-style: italic;
}
.access-btn:hover,
.access-btn:focus {
  background: #d77400;
}
.access-btn-l {
  background: #f7941e;
  padding: 15px 40px;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 22px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}
.access-btn-l:hover:focus {
  background: #d77400;
}
.work-btn {
  background: transparent;
  border: 1px solid #fff;
  padding: 20px 80px 20px 40px;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
}
.work-btn::after {
  content: "play_circle_filled";
  font-family: "Material Icons Outlined";
  color: #fff;
  font-size: 50px;
  position: absolute;
  right: 14px;
  top: 6px;
  text-transform: none;
  transition: all 0.3s ease-out 0s;
}
.work-btn:hover {
  border-color: #f7941e;
  color: #f7941e;
}
.work-btn:hover:after {
  color: #f7941e;
}
.insight-box {
  padding-bottom: 150px;
}
.insight-box h2 {
  margin-bottom: 150px;
  font-size: 40px;
}
.insight-col-box {
  max-width: 960px;
  margin: 0 auto;
}
.insight-col {
  width: 330px;
  color: #f7941e;
  font-size: 24px;
  margin: 0 0 90px;
  text-align: right;
  font-weight: normal;
}
.insight-col:nth-child(1),
.insight-col:nth-child(3) {
  margin-left: auto;
  text-align: left;
}
.insight-col:last-child {
  margin-bottom: 0;
}

.surface-box {
  padding: 0 0 100px;
  text-align: center;
}
.surface-box h2 {
  font-size: 40px;
}
.surface-box p {
  font-size: 20px;
  line-height: 28px;
  max-width: 70%;
  margin: 0 auto 50px;
}

.experience-box {
  padding: 40px 0;
}
.experience-left {
  width: 335px;
  color: #f7941e;
  font-size: 40px;
}
.experience-right {
  width: calc(100% - 350px);
}
.experience-right p {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
}

.feature-box {
  background: url(../../assets/feature-bg.jpg) no-repeat center top;
  padding: 70px 0 50px 0;
}

.feature-col {
  max-width: 320px;
  position: relative;
  margin-top: 142px;
}
.feature-col:after {
  content: "";
  background: #f7941e;
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 100%;
}
.feature-col h4{ min-height: 48px; }
.feature-col p { min-height: 75px; margin: 0; }

.feature-col:nth-child(2), .feature-col:nth-child(3) {
  margin-top: 0;
}

.feature-col-box {
  margin-bottom: 315px;
}

.access-box {
  padding: 80px 0;
}
.access-left {
  border: 1px solid #fff;
  border-radius: 10px;
  position: relative;
  width: 450px;
  height: 300px;
}
.access-play-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  padding: 0;
  text-align: center;
}
.access-play-btn span {
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

.access-right {
  width: calc(100% - 500px);
}
.access-right p {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
}

.trust-box {
  padding: 100px 0 230px;
  background: url(../../assets/trust-bg.png) no-repeat bottom center;
}
.trust-slider {
  max-width: 450px;
  margin: 0 auto;
  position: relative;
}
.trust-slider .item {
  text-align: center;
  font-family: "Fenice BT";
  color: #fff;
  font-size: 60px;
}

.trust-slider.owl-carousel .owl-dots {
  display: none;
}
.trust-slider.owl-carousel .owl-nav button.owl-next,
.trust-slider.owl-carousel .owl-nav button.owl-prev {
  width: 15px;
  height: 27px;
  background-size: 100%;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  font-size: 0;
  opacity: 0.3;
}
.trust-slider.owl-carousel .owl-nav button.owl-next {
  background: url(../../assets/right-arrow.png) no-repeat;
  right: 0;
}
.trust-slider.owl-carousel .owl-nav button.owl-prev {
  background: url(../../assets/left-arrow.png) no-repeat;
  left: 0;
}

.trust-slider.owl-carousel .owl-nav button.owl-next:hover,
.trust-slider.owl-carousel .owl-nav button.owl-prev:hover {
  opacity: 1;
}

.expert-box {
  background: url(../../assets/expert-bg.png) no-repeat top center;
  padding: 0 0 100px 0;
}
.experts-right {
  width: 48%;
  border-radius: 20px;
  overflow: hidden;
}
.experts-right img {
  vertical-align: top;
}

.experts-left {
  width: 48%;
}
.experts-description {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
}

.experts-info {
  position: relative;
  padding-left: 100px;
  padding-top: 30px;
  min-height: 88px;
}
.experts-info-image {
  position: absolute;
  left: 0;
  top: 0;
}
.experts-info p {
  margin: 0;
}

.section-title {
  color: #f7941e;
  font-size: 40px;
  margin: 0 0 20px;
}
.testimonial-cover {
  background: url(../../assets/testimonial-bg.jpg) no-repeat center top;
}

.testimonial-box {
  padding: 70px 0 35px;
}
.testimonial-inner-box {
  margin-top: 130px;
  border: 1px solid #f7941e;
  border-radius: 10px;
  padding: 100px;
  position: relative;
}
.quote-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  z-index: 1;
  margin: auto;
  text-align: center;
}
.testimonial-inner-box p {
  color: #fff;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 40px;
}
.testimonial-inner-box h6 {
  margin: 0;
  font-size: 500;
  font-size: 26px;
}

.whitepaper-box {
  padding: 80px 0;
}
.whitepaper-left {
  border-radius: 10px;
  overflow: hidden;
  width: 30%;
}
.whitepaper-right {
  width: 63%;
}
.whitepaper-right h2 {
  text-align: left;
}

.trial-box {
  padding: 150px 0;
  background: url(../../assets/trial-bg.png) no-repeat left center;
}
.trial-sign-box {
  display: flex;
  justify-content: center;
}
.email-input {
  width: 350px;
  height: 45px;
  border-radius: 30px;
  border: none;
  padding: 0 30px;
  color: #000;
  font-size: 16px;
  font-weight: 300;
  margin-right: 15px;
}
.submit-btn {
  background: #f7941e;
  padding: 0 40px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 18px;
}
.submit-btn:hover {
  background: #d77400;
}

.cover-box {
  background: url(../../assets/cover-bg.png) no-repeat bottom center;
}
.FAQ-box {
  padding: 50px 0 80px;
}
.FAQ-box .section-title {
  margin-bottom: 80px;
}

.accordion {
  border: none;
  box-shadow: none;
  border-radius: 0;
  max-width: 850px;
  margin: 0 auto;
}
.accordion .accordion-item {
  border-radius: 10px;
  margin-bottom: 30px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding: 0;
}
/* .accordion .accordion-item:last-child{ margin-bottom: 0; } */
.accordion .accordion-item .accordion-button {
  border-radius: 0;
  background: none;
  border: none;
  padding: 30px 70px 30px 60px;
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  box-shadow: none;
  margin: 0;
  line-height: normal;
  display: block;
}
.accordion .accordion-item .accordion-button::after {
  content: "add";
  color: #f7941e;
  position: absolute;
  right: 50px;
  top: 50%;
  font-size: 34px;
  font-family: "Material Icons Outlined";
  margin-top: -20px;
  background: none;
}
.accordion .accordion-item .accordion-button:not(.collapsed)::after {
  content: "remove";
  transform: none;
}
.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
  border: none;
}
.accordion .accordion-item .accordion-body {
  padding: 0 60px 30px;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  font-weight: 300;
  text-align: left;
}
.accordion .accordion-item .accordion-body p {
  margin-bottom: 20px;
}
.accordion .accordion-item .accordion-body p,
.accordion .accordion-item .accordion-body ul li {
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  font-weight: 300;
}
.accordion .accordion-item .accordion-body p:last-child {
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-body ul {
  margin: 0 0 20px;
  padding: 0 0 0 20px;
}
.accordion .accordion-item .accordion-body ul ul {
  margin-top: 5px;
}
.accordion .accordion-item .accordion-body li {
  margin-bottom: 5px;
}
.accordion .accordion-item .accordion-body li:last-child {
  margin-bottom: 0;
}
.accordion .accordion-item .accordion-body b {
  font-weight: 500;
}

.FAQ-box .accordion .accordion-item:nth-child(6),
.FAQ-box .accordion .accordion-item:nth-child(7),
.FAQ-box .accordion .accordion-item:nth-child(8),
.FAQ-box .accordion .accordion-item:nth-child(9),
.FAQ-box .accordion .accordion-item:nth-child(10),
.FAQ-box .accordion .accordion-item:nth-child(11) {
  transition: all 0.1s ease-out 0s;
  display: none;
}

.load-more .accordion .accordion-item:nth-child(6),
.load-more .accordion .accordion-item:nth-child(7),
.load-more .accordion .accordion-item:nth-child(8),
.load-more .accordion .accordion-item:nth-child(9),
.load-more .accordion .accordion-item:nth-child(10),
.load-more .accordion .accordion-item:nth-child(11) {
  display: block;
}

.load-btn {
  border: 1px solid #f7941e;
  color: #f7941e;
  background: none;
  font-size: 16px;
  border-radius: 30px;
  padding: 10px 30px;
}
.load-btn:hover {
  background: #f7941e;
  color: #fff;
}
.load-btn .less {
  display: none;
}
.load-more .load-btn .less {
  display: block;
}
.load-more .load-btn .view {
  display: none;
}

.product-info-box {
  padding: 80px 0 150px;
}
.product-info-col { margin: 0 20px;
  width: 31%;
  min-height: 470px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 80px 70px 80px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-out 0.1s;
}
.product-info-col:hover {
  transform: scale(1.014);
  border-color: #f7941e;
}
.product-info-col h3 {
  border-bottom: 1px solid #fff;
  padding: 0 15px 20px;
  color: #f7941e;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 30px;
}
.product-info-col p {
  font-size: 20px;
}
.product-info-col .btnlandingpage {
  margin-top: auto;
  margin-right: 0;
}

.reach-box {
  padding: 0 0 70px 0;
}
.reach-box .section-title {
  margin-bottom: 80px;
}
.reach-col {
  margin-right: 100px;
  text-align: center;
}
.reach-col:last-child {
  margin-right: 0;
}
.reach-col h4 {
  color: #fff;
  font-weight: 700;
}
.reach-col a {
  color: #f7941e;
  font-weight: normal;
  margin-right: 30px;
}
.reach-col a:last-child {
  margin-right: 0;
}

.modal-open .wrapper {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.modal-box .MuiModal-backdrop {
  background: rgba(0, 0, 0, 0.8);
  opacity: 1 !important;
}
.modal-box .MuiModal-backdrop:after {
  content: "X";
  position: absolute;
  right: 20px;
  top: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer; border: 1px solid #fff; padding: 3px 10px; border-radius: 6px;
}
.video-modal .modal-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  height: calc(100% - 110px);
  max-width: 100%;
  margin: 0;
  padding: 60px o 50px;
  pointer-events: auto;
}
.video-modal .modal-dialog .modal-body {
  width: 100%;
  height: 100%;
  padding: 0;
  background: #000;
}
.video-modal .modal-dialog .modal-body video,
.video-modal .modal-dialog .modal-body iframe {
  width: 100%;
  height: 100%;
  border: none;
  vertical-align: top;
}

.video-modal .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: auto;
  height: auto;
  background: none;
  color: #fff;
  opacity: 1;
  font-size: 20px;
  font-weight: 300;
  z-index: 1;
}
.video-modal .btn-close span {
  vertical-align: middle;
  font-size: 28px;
}

.signup-box-container {
  display: flex;
  flex-direction: column;
}
.signup-btn-height {
  height: 47px;
}

.close-none.modal-box .MuiModal-backdrop:after {
  display: none;
}
.hubspot-form-box:after {
  content: "X";
  position: absolute;
  right: 0;
  top: -30px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  pointer-events: none;
}

/****************************************************************
Media CSS
****************************************************************/

@media screen and (min-width: 1921px) {
  .top-section {
    background-size: cover;
  }
}

@media screen and (max-width:1399px) {

  .product-info-col{ padding: 80px 50px 80px; }
  .product-info-col h3{ font-size: 28px; }
  .feature-col{  width: 250px; }
  .feature-col p{ min-height: 70px; }

}

@media screen and (max-width: 1199px) {
  /*Footer*/
  .wide-wid.ftr-col {
    width: 100%;
    margin-bottom: 50px;
  }
  .ftr-col {
    width: 33.333%;
  }
  .footer {
    padding: 50px 0 0;
  }
  .detail-left,
  .detail-right {
    width: 100%;
  }

  .product-info-col{ padding: 80px 30px 80px; }
  .product-info-col h3{ font-size: 24px; }
}

@media screen and (max-width: 991px) {
  /*Header*/
  .header {
    padding: 0 20px;
    transition: all 0.3s ease 0s;
  }
  .logo {
    text-align: left;
    padding: 15px 0;
  }
  .logo img {
    height: 33px;
    /* margin-top: 7px; */
  }
  .nav-site {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgb(0, 0, 0);
    width: 100%;
    text-align: left;
    display: none;
  }
  .navigation li a {
    padding: 20px;
    border-bottom: 1px solid #2f2f2f;
  }
  .navigation li a:hover,
  .navigation li.cta a:hover,
  .navigation li.custom-btn a:hover {
    background: #eee;
    color: #333;
    border-color: #ddd;
  }
  .navigation li.cta,
  .navigation li.custom-btn {
    margin: 0;
  }
  .navigation li.cta a,
  .navigation li.custom-btn a {
    padding: 20px;
    background: none;
    color: #fff;
    border-radius: 0;
    border-width: 0 0 1px 0;
    border-color: #2f2f2f;
  }
  .navigation li a i {
    float: right;
  }
  .nav-site > li.hover > .submenu {
    width: 100%;
    background: #f7f7f7;
    position: static;
    display: block;
  }
  .nav-site li .submenu {
    display: none;
  }
  .navigation li.cta,
  .navigation li.custom-btn {
    text-align: left;
  }
  .abt-container h1,
  .abt-container h2 {
    font-size: 30px;
  }
  .toggleMenu span {
    display: block;
    height: 2px;
    transition: all 0.3s ease 0s;
    border-radius: 3px;
    width: 30px;
    margin: 5px 0;
    background: #fff;
  }
  .toggleMenu.active span {
    background: #fff;
  }
  .toggleMenu {
    position: static;
    left: 0;
    padding: 27px 0;
    outline: none;
    top: -5px;
  }
  .nav-site > li {
    float: none;
  }
  .nav-site > li > .parent {
    background-position: 95% 50%;
  }
  .nav-site li li .parent {
    background-image: url(../../assets/down-arrow.png);
    background-repeat: no-repeat;
    background-position: 95% 50%;
  }
  .nav-site ul {
    display: block;
    width: 100%;
  }
  .nav-site li li.hover ul,
  .nav-site > li.hover > ul {
    position: static;
  }

  h1 {
    font-size: 36px;
    margin: 0 0 20px;
  }
  .banner p {
    font-size: 20px;
    line-height: 30px;
    margin: 0 auto 20px;
  }
  .access-btn,
  .work-btn {
    font-size: 16px;
  }
  .work-btn::after {
    font-size: 40px;
  }
  .insight-box h2,
  .surface-box h2 {
    font-size: 30px;
  }
  .insight-col {
    font-size: 22px;
  }
  .surface-box h2 {
    margin-bottom: 20px;
  }
  .feature-col-box{ flex-wrap: wrap; }
  .feature-col { margin-top: 0; width: 48%; margin-bottom: 50px; }
  .feature-col::after{ display: none;  }
  .feature-col h4, .feature-col p{ min-height: auto; }

  .access-left {
    width: 300px;
    height: 250px;
  }
  .access-right {
    width: calc(100% - 330px);
  }
  .experts-left h3 {
    font-size: 24px;
  }
  .experts-description {
    font-size: 30px;
  }
  .testimonial-box {
    padding: 200px 0 35px;
  }
  .product-info-col {
    margin: 0 auto 50px;
    width: 45%;
    padding: 50px 30px 40px;
    min-height: 400px;
  }
  .product-info-col h3{ font-size: 30px; }
  .accordion .accordion-item .accordion-button {
    padding: 30px 70px 30px 40px;
    font-size: 20px;
  }
  .reach-col {
    width: 100%;
    margin: 0 0 30px;
  }
  .reach-col:last-child {
    margin: 0;
  }
 
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .top-section {
    background-size: cover;
    background-position: center 260px;
    position: relative;
  }
  .top-section:after {
    content: "";
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.34) 66%,
      rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 550px;
  }
  .carbon-logo img {
    width: 200px;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  .banner {
    min-height: 1000px;
    position: relative;
    z-index: 1;
  }
  .banner p,
  .access-right p,
  .surface-box p {
    font-size: 16px;
    line-height: 24px;
  }
  .access-btn,
  .work-btn {
    display: block;
    margin: 0 auto;
    max-width: 240px;
    padding: 15px 20px;
  }
  .access-btn {
    margin-bottom: 20px;
  }
  .work-btn {
    padding: 20px 80px 20px 20px;
  }
  .insight-box h2,
  .surface-box h2 {
    font-size: 26px;
    margin-bottom: 100px;
  }
  .insight-col {
    font-size: 20px;
    width: 280px;
    margin: 0 auto 50px;
    text-align: center;
  }
  .insight-col:nth-child(1),
  .insight-col:nth-child(3) {
    text-align: center;
  }
  .surface-box h2 {
    margin-bottom: 20px;
  }
  .surface-box p {
    margin-bottom: 30px;
    max-width: 100%;
  }

  .experience-left,
  .experience-right {
    width: 100%;
    text-align: center;
  }
  .experience-left {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .experience-right p {
    font-size: 16px;
    line-height: 24px;
  }
  .feature-box {
    background-size: 800px;
    background-position: bottom center;
  }
  .feature-col {
    margin: 0 auto 50px;
    width: 100%;
  }
  .feature-col::after {
    display: none;
  }
  .feature-col-box {
    margin-bottom: 100px;
  }
  .access-left {
    width: 300px;
    height: 250px;
    margin: 0 auto 30px;
  }
  .access-right {
    width: 100%;
    text-align: center;
  }

  .access-box {
    padding: 50px 0;
  }
  .trust-box {
    padding: 50px 0 100px;
  }

  .trust-slider .item {
    font-size: 34px;
  }
  .section-title {
    font-size: 26px;
  }
  .experts-left {
    width: 100%;
    text-align: center;
  }
  .experts-right {
    max-width: 400px;
    width: 100%;
    margin: 30px auto 0;
  }
  .experts-left h3 {
    font-size: 20px;
  }
  .experts-description {
    font-size: 22px;
  }
  .experts-info {
    text-align: left;
    margin: 0 auto;
    padding-top: 10px;
    max-width: 315px;
  }
  .expert-box {
    padding-bottom: 50px;
  }
  .testimonial-box {
    padding: 80px 0 50px;
  }
  .testimonial-inner-box {
    margin-top: 80px;
    padding: 50px 30px;
  }
  .quote-icon {
    top: -45px;
  }
  .quote-icon img {
    width: 80px;
  }
  .testimonial-inner-box p {
    font-size: 18px;
    line-height: 30px;
  }
  .testimonial-inner-box h6 {
    font-size: 20px;
  }
  .whitepaper-box {
    padding: 50px 0;
  }
  .whitepaper-left {
    margin: 0 auto 30px;
    width: 100%;
    max-width: 300px;
  }
  .whitepaper-right {
    width: 100%;
    text-align: center;
  }
  .whitepaper-right h2 {
    text-align: center;
  }
  .trial-box {
    padding: 50px 0;
  }
  .trial-sign-box {
    display: block;
    text-align: center;
  }
  .email-input {
    margin: 0 auto 10px;
    width: 280px;
  }
  .submit-btn {
    padding: 10px 40px;
  }
  .FAQ-box .section-title {
    margin-bottom: 40px;
  }
  .accordion .accordion-item .accordion-button {
    padding: 20px 40px 20px 30px;
    font-size: 18px;
  }
  .accordion .accordion-item .accordion-button::after {
    right: 25px;
    top: 50%;
    margin-top: -14px;
    font-size: 26px;
  }
  .accordion .accordion-item .accordion-body {
    padding: 0 30px 30px;
  }
  .FAQ-box {
    padding-bottom: 50px;
  }
  .product-info-box {
    padding: 50px 0;
  }
  .product-info-box .section-title br {
    display: none;
  }
  .product-info-col {
    width: 90%;
    margin: 0 auto 30px;
    max-width: 400px;
    padding: 40px 40px 20px;
    min-height: 350px;
  }
  .product-info-col:last-child {
    margin-bottom: 0;
  }
  .product-info-col .btnlandingpage { margin-left: auto;
    margin-right: auto;
  }
  .product-info-col h3 {
    font-size: 24px;
  }
  .product-info-col p {
    font-size: 18px;
  }
 
  .reach-box .section-title {
    margin-bottom: 30px;
  }
  .cover-box {
    padding: 50px 0 30px;
  }
  .reach-box {
    padding-bottom: 50px;
  }

  .ftr-col,
  .wide-wid.ftr-col {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
  .copyright {
    margin-top: 0;
  }
  .reach-col a{ display: block; margin: 0 0 10px; }
  .reach-col a:last-child{ margin-bottom: 0; }
}
