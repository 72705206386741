.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-28 {
  font-size: 28px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

.font-34 {
  font-size: 34px;
}

.font-40 {
  font-size: 40px;
}

.font-42 {
  font-size: 42px;
}

.font-46 {
  font-size: 46px;
}

.font-56 {
  font-size: 56px;
}

.font-64 {
  font-size: 64px;
}
