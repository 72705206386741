@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif; 
  scroll-behavior: smooth;
}
.scrollbar
{

}
