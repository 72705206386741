/* These styles are used for payment status page */
/* .pay-container-box {
  display: flex;
  justify-content :center;
} */


.pay-container-box{ padding: 50px 0; }
.pay-inner-box{ text-align: center; }
.confirmed-icon{ margin-bottom: 20px;  }
.pay-title{ margin-bottom: 10px; }


.pay-success{ color:#00a412; }
.pay-error{ color:#f00; }

/* .pay-status-message {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
} */

.message{ margin: 0;}
.pay-msg {
  color: #F7941E;
  margin-top: 10px;
}

