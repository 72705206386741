.parent-signup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 30px;
}

.left-parent-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-child-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px;
}

.tick-image {
  width: 18px;
  height: 16px;
}

.bottom-box-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.bottom-box-child {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.right-parent-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 50px 30px 50px;
}

.right-child-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 50px 30px 50px;
}

.form-field {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#discover-text {
  font-size: 48px;
  font-weight: 800;
}

#sub-heading-text {
  font-size: 16px;
  padding-bottom: 20px;
}

#heading-text {
  font-size: 16px;
  font-weight: 600;
}

.input-field-box-signup {
  height: 90px;
}

#form-field-text {
  font-size: 14px;
  font-weight: 600;
}

#already-account-text {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 10px;
}
.sign-up-img {
  height: 80%;
  width: 80%;
}

.password-helper-text {
  margin-top: -5px;
  margin-bottom: 20px;
}

.field-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.signup-box {
  padding: 100px 0;
}

.signup-box .letter-left,
.signup-box .letter-right {
  width: 45%;
}

.signup-box .letter-right {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px 30px;
}

.signup-box .letter-right p {
  line-height: normal;
  margin-bottom: 15px;
}

/* .signup-box .letter-right p:last-child {
  margin-bottom: 0;
} */

.field-input-signup {
  width: 100%;
  height: 50px;
  border: 1px solid #000;
  padding: 0 10px;
  border-radius: 5px;
  background: #fff;
  color: #000 !important;
  outline: none;
}

.input-checkbox-container {
  display: flex;
  gap: 10px;
}

.checkbox-box {
  width: 20px;
  height: 20px;
}

.privacy-input-box {
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
}
.privacy-checkbox {
  position: absolute;
  left: 0;
  top: 2px;
}
.privacy-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.privacy-checkbox input + label {
  border-radius: 5px;
  background: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.privacy-checkbox input:checked + label:after {
  content: "done";
  font-family: "Material Icons Outlined";
  color: #00a412;
  font-size: 16px;
}
