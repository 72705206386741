.main-confirmation {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
 
}

#confirm-heading {
  font-size: 20px;
  margin-bottom: 20px;
}

#heading-detail {
  margin-bottom: 20px;
}
