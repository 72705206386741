.verified-text {
  text-align: center;
  margin-bottom: 20px;
}

.verified-text label {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 8px 40px;
  border-radius: 4px;
}

.verified-text span {
  vertical-align: top;
  margin-right: 10px;
  font-size: 20px;
  color: #00a412;
  display: inline-block;
  margin-top: -2px;
}

.price-title {
  text-align: center;
  margin: 0 0 30px;
}

.price-title h4 {
  margin-bottom: 5px;
  font-size: 44px;
}

.price-title p {
  font-size: 20px;
}

.price-col-box {
  display: flex; justify-content:center; flex-wrap: wrap;
}

.price-col {
  margin:0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width:23%; margin: 0 10px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  text-align: center;
}

.price-col h4 {
  margin-bottom: 30px;
  font-weight: 500;
}

.price {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  font-weight: 300;
}

.price-col p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
}

.price-col ul,
.payment-left ul {
  margin: 0 auto 30px;
  padding: 0;
  text-align: left;
  max-width: 200px;
}

.price-col ul li,
.payment-left ul li {
  margin-bottom: 5px;
  font-size: 16px;
}

.final-price {
  margin-right: 10px;
  font-size: 32px;
  color: #fff;
  font-weight: 700;
}

.blackBG {
  background-color: black;
}

.price-col > .btn {
  width: 150px;
  margin: auto auto 0;
}

@media screen and (max-width: 1199px) {
  .price-col-box{ justify-content: center; }
  .price-col{ width: 30%; margin: 0 10px 20px; }
}


@media screen and (max-width: 991px) {
  
  .price-col{ width: 45%; margin: 0 10px 20px; }
}
