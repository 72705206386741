.ftr-col {
  width: 20%;
  float: left;
  padding: 0 20px;
}
.wide-wid.ftr-col {
  width: 40%;
  padding-right: 40px;
}
.wide-wid h2 {
  color: #fff;
  font-weight: 300;
  font-size: 25px;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 0;
}
.wide-wid a {
  color: #fff;
  display: inline-block;
  margin-top: 25px;
  background: #f7941e;
  border-radius: 20px;
  border: 1px solid #f7941e;
  padding: 8px 25px;
  font-weight: 500;
}
.wide-wid a:hover {
  color: #fff;
  background: #d77400;
  border-color: #d77400;
}
.footer {
  background: #000;
  padding: 80px 0 0; margin-top: auto;
}
.footer h3,
.footer h3 a {
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #fff;
  text-transform: none;
}

.footer ul {
  padding: 0;
  margin: 0 0 20px;
}
.footer li {
  margin-bottom: 12px;
  line-height: 20px;
  list-style: none;
}
.footer li a {
  font-size: 13px;
  color: #fff;
  opacity: 0.6;
  transition: all 0.3s ease 0s;
  letter-spacing: 0.5px;
}
.footer li a:hover {
  opacity: 1;
  color: #f7941e;
  text-decoration: none;
}
.copyright {
  margin-top: 30px;
  padding: 25px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: #555c6b;
  text-align: center;
  font-size: 12px;
}
.copyright p {
  margin: 0;
  text-transform: capitalize;
  padding: 0 20px; font-size: 12px;
}
.footer .ftr-social li a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  color: #0c162b;
  margin-right: 0;
  display: inline-block;
  line-height: 28px;
  text-align: center;
}
.footer .ftr-social li svg {
  height: 13px;
  margin-top: 0;
  width: auto;
}
.footer .ftr-social li a:hover {
  background: #f7941e;
}
.footer .ftr-social li a:hover svg path {
  fill: #fff;
}
.footer .ftr-social li {
  display: inline-block;
  margin-right: 10px;
}

.download-progress-box {
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
}

.box2 {
  width: 100px;
  height: 100px;
  background-color: #3498db;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.download-progress{ position: fixed; bottom:0; right: 0; z-index: 9999; background:#fff; border-radius: 10px 0 0 0; padding: 15px 20px; width:300px; }
.download-progress p{ font-size: 14px; margin: 0 0 10px; color: #000; }
.download-progress-bar{ width: 100%; height: 15px; border-radius: 10px; background: rgba(0,0,0,1); overflow: hidden; }
.download-progress-bar span{ height: 100%; display: block;  background-size: 30px 30px; background-image: linear-gradient(135deg, rgba(247, 148, 30, 1) 25%, transparent 25%, transparent 50%, rgba(247, 148, 30, 1) 50%, rgba(247, 148, 30, 1) 75%, transparent 75%, transparent );   animation: animate-stripes 0.6s linear infinite; animation-duration:1s; text-align: right; color: #fff; font-size: 12px; padding: 0 5px; line-height: 15px; }
 
@keyframes animate-stripes {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 60px 0;
    }
  }
  @keyframes auto-progress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }