.inner-content-box{ padding:0 0 50px; }

.parent-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.left-parent-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-child-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px;
}

.tick-image-login {
  width: 18px;
  height: 16px;
}

.bottom-box-parent-login {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.bottom-box-child-login {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.right-parent-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-child-login {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

#discover-text {
  font-size: 48px;
  font-weight: 800;
}

#sub-heading-text {
  font-size: 16px;
  padding-bottom: 20px;
}

#heading-text {
  font-size: 16px;
  font-weight: 600;
}

.input-field-box-login {
  height: 85px;
}

#form-field-text-login {
  font-size: 14px;
  font-weight: 600;
}

.forgot-password-container {
  display: flex;
  justify-content: space-between;
}

#login-account-text {
  padding-top: 10px;
}

.field-row-login {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.field-col {
  width: 47%;
  margin-bottom: 20px;
}

.field-col.full-width {
  width: 100%;
}

.field-title {
  font-size: 14px;
  color: #fff;
  margin: 0 0 8px;
  display: block;
}

.field-title-code {
  font-size: 14px;
  color: #fff;
  display: block;
}

.field-title-code span {
  color: #f7941d;
}

.field-title span {
  color: #f7941d;
}

.login-right {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 50px 40px;
}

.signup-box {
  padding:50px 0;
  display: flex;
  justify-content: space-between;
}

.signup-box .letter-left,
.signup-box .letter-right {
  width: 45%;
}

.signup-box .letter-right {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px 40px;
}

/* .signup-box .letter-right p {
  margin-bottom: 5px;
} */

.signup-box .letter-right p:last-child {
  margin-bottom: 0;
}

.field-input-login {
  width: 100%;
  height: 50px;
  border: 1px solid #000;
  padding: 0 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  outline: none;
}
