.main-footer {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  z-index: 1;
  margin-bottom: 30px;
}

.left-footer {
  margin-left: 60px;
  width: 50%;
  padding-left: 30px;
}

.left-footer-details {
  font-size: 16px;
}

.right-footer {
  width: 50%;
  margin-right: 80px;
  display: flex;
  justify-content: right;
}

.right-footer-details {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  cursor: pointer;
}

.download-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.download-footer-left a {
 text-decoration: none;
 color: #f7941d;  
}