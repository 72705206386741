.payment-main-box{ padding: 50px 0; align-items: flex-start;}

.payment-left{ width: 320px; border:1px solid rgba(255, 255, 255, 0.3); padding:40px 45px; }
.payment-right{ width: calc(100% - 370px); }

/* .billingAddress label {
  color: black !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
} */

.price-label {
  font-size: 34px;
  margin-bottom: 20px;
  font-weight: 300;
}

.payment-option {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.payment-option label{ width: 100%;  padding: 20px 30px; margin: 0; }
.payment-option label span.MuiRadio-root{ color: #fff; padding: 0; margin-right: 10px;  }
.offline-field-box{ padding: 20px; margin-bottom:10px; background: rgba(255, 255, 255, 0.07); border-radius: 10px; border: 1px solid rgba(255, 255, 255, 0.3);  }
.payment-left ul{ margin: 0 0 30px; padding-left: 15px; }

.payment-right .btn {
 width: 250px;
  margin:30px auto 0;
  display: block;
  font-size: 22px;
}

@media screen and (max-width:1400px) {

  .price-label{ font-size: 22px; }

}


@media screen and (max-width:991px) {
  .payment-left, .payment-right{ width: 100%; }
  .payment-left{ margin-bottom: 20px; padding: 20px 25px; }
  

}