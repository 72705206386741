* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  line-height: 1.3;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #000000;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  min-height: 100vh;
}

/* sup {
  vertical-align: top;
  top: 4px;
  margin: 0;
  font-size: 50%;
  line-height: normal;
} */


sup {
  vertical-align: top; position: static; 
  margin:2px 0 0; display: inline-block;
  font-size: 50%;
  line-height: normal;
}



img,
svg {
  max-width: 100%;
}

/* .d-flex {
  display: flex;
} */

.justify-content-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.text-center {
  text-align: center;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}
/* .container {
  max-width: 1228px;
  margin: 0 auto;
} */

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-25 {
  margin-right: 25px;
}

.btn {
  padding: 12px 25px;
  color: #fff;
  font-size: 16px;
  border-radius: 0;
  border: none;
  background: none;
  color: #fff;
  border-radius: 25px;
}

.primary-border-btn {
  border: 1px solid #f7941d;
  background: transparent;
  color: #f7941d;
}

.primary-border-btn:hover {
  background: #f7941d;
  color: #fff;
}

.primary-btn {
  min-width: 150px;
  background: linear-gradient(
    to right,
    rgb(247, 148, 29) 0%,
    rgb(245, 130, 32) 100%
  );
}

.primary-btn:hover, .primary-btn:focus {
  background: linear-gradient(
    to right,
    rgb(208, 102, 11) 0%,
    rgb(247, 148, 29) 100%
  );
}

.btn[disabled] {
  background: rgba(255, 255, 255, 0.5);
  border-color: rgb(127, 127, 127);
  cursor: not-allowed;
  pointer-events: auto;
}
.secondary-btn {
  background: rgba(255, 255, 255, 0.3);
}

.secondary-btn:hover {
  background: #f7941d;
}

.secondary-border-btn {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: transparent;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
}

.secondary-border-btn:hover {
  background: rgba(255, 255, 255, 0.3);
}
.sub-title {
  margin-bottom: 15px;
}

input[type="button"],
input[type="submit"],
button,
a {
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
}

input,
textarea,
button {
  font-family: "Roboto", sans-serif;
}

.inner-content-box h2 {
  color: #f7941d;
  font-size: 48px;
  font-weight: 300;
  margin: 0 0 30px;
  padding: 0;
  line-height: 114%;
  text-align: left;
}

h3 {
  color: #f7941d;
  font-size: 28px;
  font-weight: 300;
}

h4 {
  color: #f7941d;
  font-size: 20px;
  font-weight: 300;
}

.download-footer {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

#main-header-btn {
  color: #ffffff;
  /* border-color: #000000;
  background: #000000;
  width: 150px;
  border-radius: 3px;
  font-weight: 700;
  text-transform: none; */
}
#begin-btn-start-now {
  color: #ffffff;
  border-color: #000000;
  background: #000000;
  width: 275px;
  border-radius: 3px;
  font-size: 20px;
  font-weight: 700;
  padding: 12px 24px 12px 24px;
  text-transform: none;
}
#landing-try-it-out-btn-sign-up {
  width: 125px;
  height: 55px;
  border-radius: 4px;
  padding: 16px 8px 16px 8px;
  background: #005da4;
  margin-left: 15px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
}

#landing-btn-sign-up {
  width: 125px;
  height: 55px;
  border-radius: 4px;
  padding: 16px 8px 16px 8px;
  background: #231f20;
  margin-left: 15px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
}

#landing-btn-see-how-it-helps {
  text-transform: none;
  padding: 12px 44px 12px 44px;
  color: #231f20;
  font-size: 20px;
  font-weight: 600;
  margin-top: 25px;
  border: 1px solid #231f20;
}

#login-btn {
  width: 100%;
  color: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 12px 24px 12px 24px;
  font-size: 16px;
  background-color: black;
  margin-top: 20px;
  text-transform: none;
}

#signup-btn {
  width: 100%;
  color: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 12px 24px 12px 24px;
  font-size: 16px;
  background-color: black;
}

.phone-number-input {
  width: 100%;
  /* border: 2px solid green; */
}

#stage-back-btn {
  width: 228px;
  color: white;
  border-radius: 0px;
  border: 1px solid black;
  padding: 12px 24px 12px 24px;
  font-size: 16px;
  background-color: black;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#stage-next-btn {
  width: 228px;
  color: white;
  border-radius: 0px;
  border: 1px solid black;
  padding: 12px 24px 12px 24px;
  font-size: 16px;
  background-color: black;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#btn-e-sign {
  width: 200px;
  height: 48px;
  background-color: black;
  color: white;
  margin: 5px 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  margin-top: 30px;
  margin-left: 20px;
  text-transform: none;
}

#phone-input {
  background: transparent;
  width: 300px;
  border: 1px solid rgb(154, 154, 154);
  border-radius: 3px;
  height: 60px;
}
#phone-input:focus {
  border: 1px solid rgb(0, 0, 0);
}

#btn-select-plan-selected {
  width: 200px;
  height: 48px;
  background-color: black;
  color: white;
  margin: 5px 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  margin-top: 60px;
  text-transform: none;
}

#btn-select-plan-selected:disabled {
  width: 200px;
  height: 48px;
  background-color: black;
  opacity: 0.5;
  color: white;
  margin: 5px 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  margin-top: 60px;
  text-transform: none;
}

#btn-select-plan {
  width: 200px;
  height: 48px;
  background-color: grey;
  color: white;
  margin: 5px 0px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  margin-top: 60px;
  text-transform: none;
}
#btn-pay-now {
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  text-transform: none;
}
#btn-home {
  background-color: black;
  color: white;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  text-transform: none;
}

.navLink {
  color: #f7941d;
  cursor: pointer;
}

div.stepLabel > span {
  display: flex;
  flex-direction: column;
}

.payment-left {
  width: 320px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 40px 45px;
}

.payment-right {
  width: calc(100% - 370px);
}

.select-plan {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
  margin: 0 0 20px;
}

.price {
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 20px;
  font-weight: 300;
}

.payment-left ul {
  margin: 0 auto 30px;
  padding: 0;
  text-align: left;
  max-width: 200px;
}

.payment-left ul li {
  margin-bottom: 5px;
  font-size: 16px;
}



.payment-option input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.payment-option input + label {
  color: #1e2a2b;
  font-size: 18px;
  line-height: 30px;
  padding-left: 45px;
  position: relative;
  cursor: pointer;
  display: block;
}

.payment-option input + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #000000;
  background: #f8fafc;
}

.payment-option input:checked + label::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 1;
  background: #000;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.payment-field-box {
  background: #f8fafc;
  border-radius: 10px;
  padding: 25px 30px;
  margin-bottom: 10px;
}

.total-row {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  border: 1px solid rgba(255, 255, 255, 0.3);
}



.error-message {
  color: #f00;
  display: block;
  font-size: 12px;
  margin: 3px 0 0;
}

.download-progress {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: #fff;
  border-radius: 10px 0 0 0;
  padding: 15px 20px;
  width: 300px;
}
.download-progress p {
  font-size: 14px;
  margin: 0 0 10px;
  color: #000;
}
.download-progress-bar {
  width: 100%;
  height: 15px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
}
.download-progress-bar span {
  border-radius: 10px;
  position: relative;
  height: 100%;
  display: block;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba(247, 148, 30, 1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(247, 148, 30, 1) 50%,
    rgba(247, 148, 30, 1) 75%,
    transparent 75%,
    transparent
  );
  animation: animate-stripes 0.6s linear infinite;
  animation-duration: 1s;
  text-align: right;
  color: #fff;
  font-size: 12px;
  padding: 0 5px;
  line-height: 15px;
}

.Toastify__toast {
  background: #000;
  color: #fff;
  padding: 8px 12px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.Toastify__toast .Toastify__toast-body {
  padding: 0;
}
.Toastify__toast button {
  opacity: 0.6;
  align-self: center;
}
.Toastify__toast button svg {
  width: 18px;
  height: 18px;
  fill: #fff;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
@keyframes auto-progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.progress-count {
  position: absolute;
  right: -15px;
  top: -23px;
  width: auto;
  height: auto;
  color: #f7941d;
  padding: 0;
  font-size: 11px;
  width: 50px;
}

.download-progress-bar.exe-progress-bar {
  margin: 0 0 0 20px;
  width: 200px;
  border: 1px solid #7d7d7d;
  overflow: visible;
  height: 10px;
}
.download-progress-bar.exe-progress-bar span {
  animation: none;
  background: #f7941d;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.input-password-login {
  position: relative;
}

.input-icon-login { width:20px; cursor: pointer;
  position: absolute;  
  top: 12px;
  right: 10px;
}

.breadcrumb-box {
  background: rgba(255, 255, 255, 0.15);
  padding: 15px 0;
  margin-top: 130px;
}
.breadcrumb-box ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb-box ul li.breadcrumb-item {
  text-transform: capitalize;
  padding-left: 26px;
  position: relative; color: #fff;
}
.breadcrumb-box ul li.breadcrumb-item sup {
  top: 2px;
}

.breadcrumb-box ul li.breadcrumb-item:first-child {
  padding-left: 0;
}
.breadcrumb-box ul li.breadcrumb-item:first-child::before {
  display: none;
}
.breadcrumb-box ul li.breadcrumb-item:before {
  text-transform: none;
  content: "chevron_right";
  font-family: "Material Icons Outlined";
  color: #b3b3b3;
  font-size: 20px;
  position: absolute;
  left: 3px;
  top: -2px;
  line-height: 25px;
}

.stage-main-box {
  padding: 50px 0;
}

div.css-1etrlhw,
div.css-1qaqk6d {
  height: auto;
  margin: 20px 0;
}
div.css-1etrlhw > div,
div.css-1qaqk6d > div {
  position: static;
}

.wow { visibility: hidden;  }
 

@media screen and (max-width: 767px) {
  .breadcrumb-box {
    margin-top: 111px;
  }
  .signup-box .letter-left,
  .signup-box .letter-right {
    width: 100%;
  }
  .signup-box .letter-right {
    padding: 30px 20px;
  }
  .inner-content-box h2 {
    font-size: 30px;
    margin: 0 0 20px;
  }
  .field-col {
    width: 100%;
  }
  .letter-left,
  .letter-right {
    width: 100%;
  }
  .reset-title {
    font-size: 22px;
  }

  .download-progress-bar.exe-progress-bar {
    margin: 30px 0 0;
  }
}


.loader-backdrop svg circle{ stroke:#f7941e; }


.pdf-container object {
  width: 100%;
  height: 500px;
}

.pdf-container object .toolbar {
  display: none !important; /* Hide toolbar */
}