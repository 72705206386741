.video-modal-login  .modal-dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    height: calc(100% - 110px);
    max-width: 100%;
    margin: 0;
    padding: 60px o 50px;
    pointer-events: auto;
}

.video-modal-login .modal-dialog .modal-body {
    width: 100%;
    height: 100%;
    padding: 0;
    background: #000;
}
.video-modal-login .modal-dialog .modal-body video{width: 100%; height: 100%; border: none; vertical-align: top; }

.video-modal-login .btn-close{ position: absolute; right: 20px; top:20px; width: auto; height: auto; background: none; color: #fff; opacity: 1; font-size: 20px; font-weight: 300; z-index: 1; }
.video-modal-login .btn-close span{ vertical-align: middle; font-size: 28px; }