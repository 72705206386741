.hubspot-form-box{ position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50% ); width: 500px; border-radius: 10px; padding: 50px 30px; background: #fff}

.hubspot-form-box h3{ text-align: center; margin-bottom: 30px; }
.hubspot-form-box .field.hs-form-field{ margin-bottom:20px; }
.hubspot-form-box .field.hs-form-field label{ display: none; color: #fff; font-size: 14px; margin-bottom:8px; }
.hubspot-form-box .field.hs-form-field label .hs-form-required{ color:#ff2929; margin-left: 3px; }
.hubspot-form-box .field.hs-form-field input{ width: 100%;
  height: 50px;
  border:1px solid #ccc;
  padding: 0 10px;
  border-radius: 5px; font-size: 16px;
  background:#fff;
  color: #000;
  outline: none; }

.hubspot-form-box .hs-button.primary.large{ margin: 0 auto; display: block; min-width: 150px; background: linear-gradient(to right, rgb(247, 148, 29) 0%, rgb(245, 130, 32) 100%); color: #fff;
  border-radius: 25px; border: none; font-size: 16px; padding: 12px 25px; }
  .hubspot-form-box .hs-button.primary.large:hover{opacity: 0.6;}
  .no-list.hs-error-msgs.inputs-list{ margin:3px 0 0; padding: 0; list-style: none; }

  .hubspot-form-box .field.hs-form-field label.hs-error-msg.hs-main-font-element{ font-size: 12px; color:#ff2929; display: block; }
  .hubspot-form-box .field.hs-form-field.hs_phone.hs-phone.hs-fieldtype-phonenumber .hs-fieldtype-intl-phone{ display: flex; justify-content: space-between; }
  .hubspot-form-box .field.hs-form-field.hs_phone.hs-phone.hs-fieldtype-phonenumber .hs-fieldtype-intl-phone:after{ display: none; }
  .hubspot-form-box .field.hs-form-field.hs_phone.hs-phone.hs-fieldtype-phonenumber .hs-fieldtype-intl-phone select{height: 50px;
    border:1px solid #ccc;
    padding: 0 10px;
    border-radius: 5px; font-size: 16px;
    background:#fff;
    color: #000;
    outline: none; }

    .hubspot-form-box .field-title{ color: #000; }
  .hs_error_rollup .hs-main-font-element{ color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  
    position: relative; display: block; width: 100%;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent; border-radius: .25rem; text-align: center; font-size: 14px;}

.submitted-message.hs-main-font-element{color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;

  position: relative; display: block; width: 100%;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; border-radius: .25rem; text-align: center; font-size: 14px;}


  .billing-madal .hubspot-form-box{ padding: 0 }
  .billing-madal .hubspot-inner-box{ padding:50px 30px; }

  @media screen and (max-width:767px) {
    
    .hubspot-form-box{ width: 90%; max-width: 500px; padding: 30px 20px; }
    .billing-madal .hubspot-inner-box{ padding:30px 20px; overflow: auto; max-height: 90vh; }
    .hubspot-form-box h3{ font-size: 22px; }
  }