.drop-file-input {
  position: relative;
  /* width: 400px; */
  border: 1px dashed #000000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  border: 1px dashed #000000;
  border-radius: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.close-file-container {
  display: flex;
  align-items: center;
  justify-content:space-between;
  background:rgba(255, 255, 255, 0.07);
  color: #ffffff; padding:15px 30px; border-radius: 10px;
  
  margin-bottom: 20px;
}

.close-file-container p{ margin: 0; font-size: 16px; }

.upload-progress-box {
  width:100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color:rgba(255, 255, 255, 0.3);
  color: #000000;
  padding: 20px;
}

.upload-progress-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.upload-progress-child {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  padding: 0;
}
.upload-progress-child p{ margin: 0; }
.upload-progress-child p.fileName{ margin-right: 20px; }

.verify-failed-progress-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color:rgba(255, 255, 255, 0.3);
  color: #000000;
  border: 1px solid red;
  padding: 20px;
}

.letter-verification-failed {
 
  display: flex;
  flex-direction: column;
}

.letter-verification-failed-text {
  margin-top: 20px;
  padding-bottom: 10px;
}

.letter-verification-error-failed-text {
  padding-bottom: 10px;
}

.fileName {
  color: #fff;
}

.preview-text {
  color:#f7941d;
  cursor: pointer;
}

.verification-failed {
  color:#f00;
}

.verification-failed span {
  vertical-align: middle;
  font-size: 18px;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input__label {
  padding: 20px;
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  width: 200px;
  position: relative;
  display: flex;
  margin-bottom: 10px;
  padding: 15px;
  background: #2c2c2c;
  color: #ffffff;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 25%;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
}

.drop-file-preview__item__del {
  color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  /* opacity: 0; */
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

/* .box {
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
} */

#letter-upload-text {
  padding-bottom: 20px;
}

.close-icon {
  cursor: pointer;
}

.signed-upload-box {
  max-width: 590px;
  margin: 50px auto 0;
  text-align: center;
}

.signed-upload-box > p {
  line-height: 27px;
}

.upload-box {
  position: relative;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.upload-box > input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.upload-box > label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 55px 85px;
  cursor: pointer;
}

.upload-box > label img {
  width: 70px;
  opacity: 0.3; height: auto;
}

.upload-box > label  h5 {
  margin: 24px 0 12px;
  font-size: 20px;
  color: #f7941d;
  font-weight: 300;
}

.upload-box > label p {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 24px;
}

.upload-box > label span {
  display: block;
  margin: 0 auto;
  width: 120px;
  height: 35px;
  border-radius: 25px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  line-height: 33px;
  font-weight: 500;
}

.upload-box > input + label .icon {
  color: #fff;
  font-size: 48px;
}

.upload-close{ background: none; border: none; padding: 0; font-size: 22px; color: #fff;  }

.confirm-close-btn { min-width:auto;
  margin-right: 10px;
}

.proceed-inner-box{ background: #fff; text-align: center; padding: 50px 50px; border-radius: 25px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.proceed-inner-box h4{ margin-bottom: 20px; }
.proceed-inner-box p{color: #000; }


@media screen and (max-width: 767px) {
  .proceed-inner-box{ width: 100%; max-width: 300px; padding: 30px; }
  .signed-upload-box{ width: 95%; }

  .upload-progress-child{ display: block; }
  .upload-progress-child p.fileName{ margin: 0 0 10px; }
  
}
