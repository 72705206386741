.invoice-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.invoice-history-container {
  cursor: pointer;
}

.invoice-table {
  border: 1px solid white;
}

tr,
th,
td {
  border: 1px solid white;
  padding: 5px;
}

.company-address {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}
