.dashboard-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#btn-dashboad-download {
  width: 90%;
  height: 48px;
  background-color: black;
  color: white;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  text-transform: none;
  margin-left: 5%;
}

#btn-dashboad-download:disabled {
  width: 90%;
  height: 48px;
  background-color: black;
  opacity: 0.5;
  color: white;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 12px 24px 12px 24px;
  text-transform: none;
  margin-left: 5%;
}

.grid-item-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#dashboard-view-all {
  font-size: 20px;
  font-weight: 700;
}

.download-progress-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-icon {
  cursor: pointer;
}

.dashboard-preview-text {
  color: blue;
  cursor: pointer;
}

/* new */

.profile-information-box {
  /* background: rgba(255, 255, 255, 0.07); */
  padding: 40px 0;
}

.border-none{ border: none !important; }
.padding-none{ padding: 0 !important; } 
.inline-block{ display: inline-block;}

.info-col {
  width: 31%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 20px;
}

.info-col .notification-header-profile {
  position: static;
  margin-bottom: 10px;
}

.user-name {
  margin-bottom: 20px;
}

.user-name h4 {
  margin-bottom: 5px;
}

.user-name p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.user-detail ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.user-detail li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 8px;
}

.user-detail li:last-child {
  margin: 0;
}

.user-select-plan,
.contract-box {
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}

.user-select-plan .price span {
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.user-select-plan p {
  font-size: 14px;
  color: #fff;
}

.selected-plan {
  margin-bottom: 20px;
  font-weight: 500;
  color: #f7941d;
}

.selected-plan a {
  display: inline-block;
  margin-left: 20px;
}

.contract-box {
  margin: 0;
}

.contract-box img {
  width: 22px;
}

.contract-box p {
  margin: 0 30px 0 10px;
  font-size: 14px;
}

.contract-box a {
  text-decoration: underline;
  color: #f7941d;
  font-size: 12px;
}

.info-col .download-info{ width:100%; }
.info-col .download-info img{ width:70px; left:50px; top:20px; opacity: 0.7; }

.journey-box {
  padding: 60px 0;
}

.journey-title {
  text-align: center;
  margin-bottom:40px;
}
.journey-title  h3{ font-size: 36px; }

.journey-title p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
}

/* .journey-box .tutorial-col,
.journey-box .tutorial-col:nth-child(2n) {
  width: 32%;
  margin-right: 2%;
}

.journey-box .tutorial-col:last-child {
  margin-right: 0;
} */

.support-col-box {
  justify-content: center;
  text-align: center;
}

.support-col {
  width: 25%;
}

.support-col:last-child {
  margin-right: 0;
}

.support-icon {
  margin-bottom:20px;
}

.support-icon img {
  width: 60px;
}

.support-col h4 {
  margin-bottom: 5px;
  font-weight: 400;
}

.support-col p {
  color: rgba(255, 255, 255, 0.6);
}

.accordion-box {
  position: relative;
  margin: 0 auto 20px;
}

.accordion-list {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  text-align: left;
  cursor: pointer;
}

.accordion-list > div a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #f7941d;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-weight: 300;
  font-size: 16px;
  position: relative;
}

.accordion-list > a:after {
  /* content: "add";
  font-family: "Material Icons Outlined"; */
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 22px;
}

.accordion-list > a.active:after {
  content: "remove";
}

.accordion-content {
  background: rgba(255, 255, 255, 0.6);
  padding: 20px;
}

.accordion-content,
.accordion-content p {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.accordion-content p {
  margin-bottom: 15px;
}

.accordion-content p:last-child {
  margin-bottom: 0;
}

.accordion-list > div .addIcon {
  margin-right: 10px;
}

a {
  text-decoration: none;
}

.download-license-text {
  padding:0;
  background:none;
  color: #f7941d; text-decoration: underline;
  border: none;
}

.download-license-text:disabled {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  color: white;
  border: none;
  opacity: 0.5;
}

.plan-btn {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  color: #ffffff;
  margin: 5px 5px 0px 5px;
}

.plan-btn:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #f7941d;
}
.plan-btn:disabled {
  background: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.accordion-table{ overflow: auto; width: 100%; }
.accordion-table table{ width:730px; border:1px solid rgba(255, 255, 255, 0.3); } 
.accordion-table table tr td{ border-right:1px solid rgba(255, 255, 255, 0.3); border-bottom: 1px solid rgba(255, 255, 255, 0.3); padding: 15px 10px; font-size: 16px; }
.accordion-table table tr td:first-child{ font-weight: 500; }
.accordion-table table tr td:last-child{ border-right: none; }
.accordion-table table tr:last-child td{ border-bottom: none; }

.history-link{  background: none; color: rgba(255, 255, 255, 0.3); text-decoration: none; font-size: 12px; padding: 3px 10px; border: 1px solid rgba(255, 255, 255, 0.3); border-radius: 25px;}
.history-link:hover{ color: #f7941d; border-color: #f7941d; }
.invoice-modal-box .modal-body{ background: #fff; padding: 30px; border-radius: 10px; width:95%; max-width: 800px; max-height: 70vh; overflow: auto; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.billing-modal-box .modal-body{ background: #000; padding: 30px; border-radius: 10px; width:95%; max-width: 800px; max-height: 70vh; overflow: auto; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

.invoice-table table{ border:1px solid #ccc; border-collapse: inherit; width: 100%; border-radius: 5px; }
.invoice-table table tr, .invoice-table table td, .invoice-table table th{ border: none; }
.invoice-table table thead th:first-child{ border-radius: 5px 0 0 0; }
.invoice-table table thead th:last-child{ border-radius:0 5px 0 0; }
.invoice-table table tr th{ background:#000; border-right: 1px solid #ccc; font-weight: normal; font-size: 16px; padding:13px 10px; }
.invoice-table table tr th:last-child{ border-right: none; }
.invoice-table table tr td{ border-bottom: 1px solid #ccc; border-right: 1px solid #ccc; color: #000; font-size: 14px; padding:10px 10px; }
.invoice-table table tr td:last-child{ border-right: none; }
.invoice-table table tbody tr:last-child td{ border-bottom: none; }
.invoice-table table tr:nth-child(2n) td{ background: #eee; }
.invoice-table .history-link{ border: 1px solid rgba(0, 0, 0, 0.3); color: rgba(0, 0, 0, 0.3); }
.invoice-table .history-link:hover{ color: #f7941d; border-color: #f7941d; }

.download-progress-bar.exe-progress-bar.profile-download-progress{ width: 100%; margin: 20px auto 0; }



@media screen and (max-width: 1400px) {

  .info-col .download-info{ padding: 0; text-align: center; }
  .info-col .download-info img{ position: static; margin: 0 auto 10px; }
  .info-col .download-info-left{ font-size: 30px; }

}

@media screen and (max-width: 991px) {
  /* .journey-box .tutorial-col, .journey-box .tutorial-col:nth-child(2n){ width:48%;  } */
  .user-detail li{ display: block; }
  .selected-plan a{ margin: 10px 0 0; display: block; max-width:200px; }
}

@media screen and (max-width: 768px) {
 

  /* .journey-box .tutorial-col, .journey-box .tutorial-col:nth-child(2n){ width: 100%; margin: 0 0 20px; } */
  .support-col{ text-align: center; width:100%; margin-bottom:50px; }
  .support-col:last-child{ margin-bottom: 0; }
  .info-col{ width: 100%; margin-bottom: 30px; }
  .info-col:last-child{ margin: 0; }
  .invoice-modal-box .modal-body{ padding: 20px; }
  .invoice-table{ overflow: auto; }
  .invoice-table table{ width: 600px; }


}

