.stage-main-box .css-1iazqey{ width: 100%; }
.stage-main-box .css-f938v8-MuiStepper-root{ margin-top: 0; padding-top: 0; flex-wrap: wrap; justify-content: center; }
.stage-main-box .css-f938v8-MuiStepper-root .css-j5w0w9-MuiStepConnector-root{ display: none; }

.tooltip-dropdown{ display: inline-block; position: relative; margin-left:5px; }
.tooltip-dropdown .tooltip-icon span{ color: #fff; font-size: 18px; vertical-align: top; }
.tooltip-dropdown-content{ display: none; position: absolute; left:30px; top:-20px; border-radius: 10px; background: #fff; padding:20px; width: 300px; color: #000; }
.tooltip-dropdown-content:after{ content: ""; border-right: 10px solid #fff; border-top: 10px solid transparent; border-bottom: 10px solid transparent; position: absolute; left: -8px; top: 21px; }
.tooltip-dropdown-content p{ color: #000; font-size: 12px; line-height: normal;}
.tooltip-dropdown-content ul{margin: 0; padding: 0 0 0 20px; }
.tooltip-dropdown-content ul li{ margin: 0 0 5px; color: #000; font-size: 12px; }
.tooltip-dropdown-content ul li:last-child{ margin: 0 ; }
.tooltip-dropdown:hover .tooltip-dropdown-content{ display: block; }


.field-input {
  width: 100%;
  height:45px;
  border: 1px solid #000;
  padding: 0 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  color: #000;
  outline: none;
}
.field-input[disabled]{ background: rgb(174, 174, 174); cursor: not-allowed; }


.info-box {
  margin: 0 auto;
  width: 100%;
  max-width: 630px;
  padding: 38px;
}

.field-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.field-col {
  width: 47%;
  margin-bottom: 20px;
}

.field-title {
  font-size: 14px;
  color: #fff;
  margin: 0 0 8px; 
  display: block;
}

.field-title span {
  color: #f00;
}

.mac-container {
  display: flex;
  flex-direction: row;
}

.advise-info{ color: rgba(255, 255, 255, 0.6); font-size: 14px; font-style: italic; line-height:20px; margin-bottom:15px; }
.advise-info:last-child{ margin-bottom: 0; }


@media screen and (max-width: 767px) {

  .field-col, .letter-left, .letter-right{ width: 100%; }
  .stage-main-box .css-f938v8-MuiStepper-root{ gap: 15px; }
  .letter-left{ margin-bottom: 20px; }
  .download-footer{ padding: 10px 20px; }
  .download-footer .primary-btn{ min-width: 120px; }

  .signed-upload-box{ margin-top: 30px; }
  .drop-file-input{ width: 250px; margin:0 auto; }
  .upload-box > label{ padding: 25px 15px; }
  .close-file-container{ max-width: 300px; text-align: left; margin: 0 auto 20px; }
  .price-title h4{ font-size: 30px; }
  .verified-text label{ padding: 10px 10px; }
  .verified-text span{ display: block; margin: 0 0 10px; }
  .price-col-box{ display: block; }
  .price-col{ margin: 0 0 20px; width: 100%; }
  .signed-upload-box .upload-progress-container { text-align: left; }
  .verification-failed{ margin:10px 0 0 0 !important; }
}

